import type { CustomIcon } from './types';

const Logo: CustomIcon = props => {
  return (
    <svg {...props} viewBox="0 0 32 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.7095 0.75C29.1839 0.75 31.9999 3.56625 31.9999 7.04088V26.4591C31.9999 29.9338 29.1839 32.75 25.7095 32.75H15.4052C15.1735 32.75 15.0068 32.5281 15.0718 32.3062L15.7926 29.8161C15.8576 29.5929 16.0611 29.4397 16.2928 29.4397H25.7561C27.3768 29.4397 28.691 28.1253 28.691 26.5045V6.99552C28.691 5.6628 27.8034 4.53973 26.5873 4.1805L19.9732 26.8576C19.9082 27.0795 19.7047 27.2328 19.473 27.2328H17.0174C16.7857 27.2328 16.619 27.0108 16.6839 26.7877L24.1304 1.25023C24.2175 0.953525 24.4884 0.75 24.7974 0.75H25.7095Z" />
      <path d="M17.0544 6.64241L10.406 29.4397H6.24507C4.62434 29.4397 3.31011 28.1253 3.31011 26.5045V6.99552C3.31011 5.37467 4.62434 4.06034 6.24507 4.06034H20.7188C20.9505 4.06034 21.1553 3.90709 21.219 3.68395L21.9387 1.19383C22.0024 0.971916 21.8357 0.75 21.6052 0.75H6.29043C2.81604 0.75 0 3.56625 0 7.04088V26.4591C0 29.9338 2.81604 32.75 6.29043 32.75H12.229C12.5379 32.75 12.8101 32.5465 12.8959 32.2498L20.3437 6.7123C20.4086 6.49038 20.2419 6.26724 20.0102 6.26724H17.5546C17.3229 6.26724 17.1194 6.4205 17.0544 6.64241Z" />
      <path d="M9.6413 18.4125H9.11168C8.34387 18.4125 7.72144 19.035 7.72144 19.8028V20.3325C7.72144 21.1003 8.34387 21.7228 9.11168 21.7228H9.6413C10.4091 21.7228 11.0315 21.1003 11.0315 20.3325V19.8028C11.0315 19.035 10.4091 18.4125 9.6413 18.4125Z" />
      <path d="M9.6413 10.6808H9.11168C8.34387 10.6808 7.72144 11.3033 7.72144 12.0711V12.6008C7.72144 13.3687 8.34387 13.9911 9.11168 13.9911H9.6413C10.4091 13.9911 11.0315 13.3687 11.0315 12.6008V12.0711C11.0315 11.3033 10.4091 10.6808 9.6413 10.6808Z" />
    </svg>
  );
};

export default Logo;
