import {
  Alien,
  CaretDown,
  CaretUp,
  RocketLaunch,
  Sparkle,
  Book,
  MagnifyingGlass,
  Copy,
  CheckCircle,
  AndroidLogo,
  ArrowRight,
  CaretRight,
  CaretLeft,
  GithubLogo,
  Globe,
  SealCheck,
  Link,
  Check,
  CursorClick,
  Heart,
  Article,
  RedditLogo,
  X,
  Gear,
  NetworkSlash,
  ArrowsLeftRight,
  Wrench,
  WindowsLogo,
  Desktop,
  Shuffle,
  Flag,
  PaperPlaneTilt,
  Question,
  CheckFat,
  XSquare,
  ListBullets,
  Spinner,
  PencilSimple,
  FacebookLogo,
  DevToLogo,
  ShareNetwork,
  XCircle,
  Trash,
  XLogo,
  CrosshairSimple,
  DeviceMobile
} from '@phosphor-icons/react/dist/ssr';

import {
  FullLogo,
  HalfColoredLogo,
  Logo,
  Github,
  TwitterX,
  DockerLogo,
  ElectronLogo,
  JavaLogo,
  RubyLogo,
  SquareJSLogo,
  PythonLogo,
  AppleLogo,
  LinuxLogo,
  NodeLogo,
  DenoLogo,
  BunLogo,
  FirefoxLogo,
  ChromeLogo,
  YCombinator,
  ProductHuntLogo,
} from './custom';

export {
  Alien,
  CaretDown,
  RocketLaunch,
  Sparkle,
  Book,
  FullLogo,
  HalfColoredLogo,
  Logo,
  MagnifyingGlass,
  Copy,
  CheckCircle,
  AndroidLogo,
  ArrowRight,
  Globe,
  SealCheck,
  Link,
  Github,
  TwitterX,
  FacebookLogo,
  Check,
  CaretRight,
  CaretLeft,
  GithubLogo,
  CaretUp,
  CursorClick,
  AppleLogo,
  Heart,
  Article,
  RedditLogo,
  DockerLogo,
  ElectronLogo,
  JavaLogo,
  RubyLogo,
  SquareJSLogo,
  PythonLogo,
  X,
  Gear,
  NetworkSlash,
  ArrowsLeftRight,
  Wrench,
  WindowsLogo,
  LinuxLogo,
  Desktop,
  Shuffle,
  Flag,
  PaperPlaneTilt,
  Question,
  CheckFat,
  XSquare,
  ListBullets,
  Spinner,
  PencilSimple,
  NodeLogo,
  DenoLogo,
  BunLogo,
  FirefoxLogo,
  ChromeLogo,
  DevToLogo,
  YCombinator,
  ProductHuntLogo,
  ShareNetwork,
  XCircle,
  Trash,
  XLogo,
  CrosshairSimple,
  DeviceMobile
};
